.searchContainer{
    position: absolute;
    right: 75px;
}
.searchWrapper {
    cursor: pointer;
    width: 260px;
    position: relative;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
.searchIcon {
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -271px 11px;
    width: 20px;
    height: 35px;
    cursor: pointer;
}
.searchIcon:hover{
    background-position: -174px 11px;
}
.searchIconInside{
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -172px 2px;
    width: 21px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 8px;
}
.clearSearchIcon{
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -213px 0px;
    width: 13px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 12px;
}
.clearSearchIcon:hover{
    background-position: -237px -1px;
}
.searchInput{
    background-color: rgb(20, 22, 29);
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    border: 0;
    height: 40px;
    border-bottom:  1px solid rgba(255, 255, 255, 0.6); ;
    padding-left: 30px;
    padding-right: 20px;
    font-family: var(--intro-light);
    font-size:14px;
}
.searchInput::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.searchResultWrapper {
    text-decoration: none !important;
    font-family: var(--intro-regular);
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    padding-right: 20px;
    position: absolute;
    width: 260px;
    margin-top: 5px;
    border: 1px solid   rgb(161, 162, 165);
    margin-left: 5px;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    background-color: rgb(20, 22, 29);
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
.subMenuItem {
    text-decoration: none !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: color .9s;
    color: rgba(255, 255, 255, 0.502);
    font-size: 14px!important;
    margin-left: 10px;
}


.subMenuItem:hover {
    color: rgb(255, 255, 255);
    text-decoration: none !important;
}


.category{
    font-family: var(--intro-light);
    color: rgba(255, 255, 255, 0.502);
    font-size: 12px!important;
    margin-left: 10px;
}
.notFound{
    font-family: var(--intro-light);
    color: rgba(255, 255, 255, 0.502);
    font-size: 12px!important;
    margin-left: 10px;
    height: 45px;
    display: flex;
    align-items: center;
}
.searchResultItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 45px;
    width: 260px;
    text-decoration: none !important;
    justify-content: center;
    transition: color .9s;
    color: rgba(255, 255, 255, 0.502);
    font-size: 14px;

}
.searchResultItem:not(:last-child) {
    border-bottom: 1px solid rgb(161, 162, 165);
}

@media screen and (max-width: 415px) {
    .searchWrapper{
        width: 275px;
    }
}
@media screen and (max-width: 405px) {
    .searchWrapper{
        width: 64vw;
    }
}

@media screen and (max-width: 340px) {
    .searchWrapper{
        width: 60vw;
    }
}
