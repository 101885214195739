.demoButtonMobile{
    background: url('/assets/images/DemoButtonMob.png') no-repeat;
    position: relative;
    width: 360px;
    height: 267px;
    left: 50%;
    margin-top: -282px;
    margin-left: -180px;
    margin-bottom: 46px;
}

.howToPlayButton {
    background: url('/assets/images/howToPlay.png') no-repeat;
    position: relative;
    cursor: pointer;
    width: 248px;
    height: 52px;
    margin-top: -195px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.descriptionContainer{
    color: rgb(255, 255, 255);
    width: 90%;
    margin: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 50px;
}

.jetXBanner{
    width: 100%;
}

.descriptionColumn{
    display: flex;
    flex-direction: column;
    font-family: 'Intro-Book';
    font-size: 14px;
}

.firstColumnHeader{
    display: flex;
    margin: auto;
    color: rgb(22, 156, 241);
    font-size: 16px;
    font-family: 'Intro-Bold';
    margin-bottom: 20px;
}

.firstColumnDescription{
    margin-bottom: 20px;
    display: flex;
}
.secondColumnDescription{
    margin-top: 31px;
}
.descriptionColumnLast{
    display: flex;
    flex-direction: column;
    font-family: 'Intro-Book';
    font-size: 14px;
    margin-right: 0px;
}
.icon{
    margin-right: 15px;
}
.list{
    color: rgb(22, 156, 241);
    border: 1px solid rgb(22, 156, 241);
    border-radius: 50%;
    padding: 0px 4px;
    font-size: 11px;
    font-family: 'Intro-Bold';
    margin-right: 10px;
    height: 16px;
    margin-top: 3px;
}
.laptop{
    display: flex;
}

.demoButton:hover{
    background: url('/assets/images/DemoButtonHover.png') no-repeat;
}

.howToPlayButton:hover {
    background: url('/assets/images/howToPlayHover.png') no-repeat;
}


@media screen and (max-width: 1280px){
    .demoButton{
        margin-top: -320px;
    }
    .laptop{
        flex-direction: column;
    }
}