.displaySlideBlock{
    display: block!important;
}
.carousel{
    height: 100%;
    display: flex;
    align-items: center;
}
li.slide{
min-height: 100%;
}
.carousel .control-dots {
    right: 5px!important;
    width: unset!important;
    display: flex;
    flex-direction: column;
    bottom: unset;
}

.carousel .control-dots .dot{
    margin: 6px;
    background-color:#d2d9e6 !important;
    width: 12px;
    height: 12px;
    opacity: 1!important;
}
.carousel .control-dots .dot.selected{
    background-color: #ea013a!important;
}



@media  screen and (max-width: 1200px) {
    .carousel .control-dots .dot{
        width: 8px;
        height: 8px;
        margin: 5px;
    }
}