.careerContainer {
    position: relative;
    width: 100%;
    min-height: 1000px;
}

.ongoingVacanciesContainer {
    margin-top: 100px;
    display: flex;
    justify-content: center;
}

.ongoingVacanciesWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;

}

.seeAll{
    font-family: var(--intro-regular);
    text-decoration: none !important;
}

.seeAll:hover{
    color: #007bff;
}

.ongoingVacanciesHeader {
    width: 100%;
    justify-content: space-between;
}

.ongoingVacanciesHeaderText {
    height: 44px;
    background-color: rgb(40, 44, 57);
    font-family: var(--intro-regular);
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

}

.searchContainer {
    position: relative;
}

.searchWrapper {
    cursor: pointer;
    position: relative;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

.searchIcon {
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -172px 2px;
    width: 27px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 8px;
}
.monthName{
    margin-left: 3px;
}
.searchInput {
    background: transparent;
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    border: 0;
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    ;
    padding-left: 30px;
    padding-right: 20px;
    font-family: var(--intro-light);
    font-size: 14px;
}

.searchInput::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.clearSearchIcon {
    background-image: var(--smartsoft-sprite);
    background-position: -8px -101px;
    width: 15px;
    height: 15px;
    position: absolute;
    right: -5px;
    top: 12px;
}

.clearSearchIcon:hover {
    background-position: -42px -101px;
}

.vacancyItem {
    padding-top: 20px;
    margin-bottom: 20px;
    height: auto;
    border-top: 3px solid #282c39;
}

.vacancyContainer {
    width: 100%;
    font-size: 16px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    margin-top: 30px;
    display: inline-block;
    position: relative;
    overflow-wrap: break-word;
}

.vacancyItemHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.vacancyTitle{
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: var(--intro-regular);
}

.vacancyDeadline {
    display: flex;
    font-size: 16px;
    font-family: var(--intro-book);
    align-items: center;
}

.clockIcon{
    background: url(/assets/images/clockIcon.png) no-repeat ;
    width: 14px;
    height: 14px;
}

.vacancyDeadlineText{
    font-family: var(--intro-regular);
    margin:0px 10px
}

.horizontalLine{
    background-color: #ea013a;
    width: 3px;
    height: 14px;
    margin-right: 10px;
}

.vacancyDescription {
    font-size: 14px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    margin-top: 20px;
    display: inline-block;
    position: relative;
    overflow-wrap: break-word;
}