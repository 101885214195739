.meetContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.meetItemWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
    text-decoration: none!important;
    width: 300px;
}
.meetItemWrapper:hover{
    text-decoration: none!important;
}

.meetImage {
    width: 300px;
    height: 214px;
    border-radius: 10px;
    user-select: none;
}

.meetHeadWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.meetHeadWrapperInfo {
/*    background-color: rgba(0, 0, 0, .8);
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;*/
    display: flex;
    flex-direction: column;
}

.meetTitle {
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: var(--intro-regular);
}

.publishDate {
    display: flex;
    font-size: 20px;
    color: rgb(48, 145, 255);
    font-family: var(--intro-regular);
    align-items: center;
    margin-top: 10px;
}

.monthName {
    font-size: 16px;
    font-family: var(--intro-book);
}

.meetDescription {
    font-size: 16px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    margin-top: 10px;   
    display: inline-block;
    position: relative;
    max-width: 300px;
}

.seeAll {
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    font-family: var(--intro-regular);
    font-size: 16px;
    position: relative;
    cursor: pointer;
    text-decoration: none!important;
}
.seeAll:hover{
    color: rgb(48, 145, 255);
}
.meetWrapperContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.topEvents{
    color: #ea013a;
    font-size:16px;
    background-color: rgb(20, 22, 29);
    padding: 5px 15px;
    border-radius: 5px;
    font-family: var(--intro-book);
    text-transform: uppercase;
    margin-top: 10px;
    position: relative;
    top: 20px;
}

.topMeetEvents{
    border-bottom: 3px solid rgb(20, 22, 29);
    padding-bottom: 40px;
}

