.popupContainer {
    width: 100%;
    height: 0px;
    position: fixed;
    padding-bottom: 56.250%;
    z-index: 1;
    
}

.iframe {
    z-index: 99991;
    width: 80%;
    height: 80%;
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);   
}

.contactPopupBackground {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgb(20, 22, 29);
    z-index: 99990;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@media screen and (max-width: 1200px) {
    .iframe{
        height: 56%;
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .iframe{
        height: 40%;
        width: 90%;
    }
}