.partnersContainer{
    display: flex;
    margin-top:80px;
    flex-direction: column;
    align-items: flex-start;
}

.partnersHeader {
    height: 30px;
    background-color: rgb(22 27 44);
    font-family: var(--intro-regular);
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    padding-left:10px;
    padding-right:10px;
    margin-left: 15px;
    display: flex;
    align-items: center;
}
.partnersWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.partnerIcon {
    margin: 15px;
}
.partnersHeader:nth-of-type(2){
    margin-top: 80px;
}
.mediaPartners{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.gamingPartners{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}