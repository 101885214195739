.vacancyItemWrapper {
    width: 810px;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
}

.vacancyLink {
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    margin-top: 30px;
}

.publishDate {
    font-size: 16px;
    display: flex;
    font-family: var(--intro-regular);
    color: rgba(255, 255, 255, 0.302);
    margin-top: 30px;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.vacancyTitle {
    font-size: 30px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-top: 5px;
}

.vacancyDescription {
    font-size: 14px;
    font-family: var(--intro-book);
    justify-content: center;
}


.backToVacancy {
    font-size: 18px;
    font-family: var(--intro-regular);
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    text-decoration: none !important;
}

.backToVacancy:hover {
    color: rgb(48, 145, 255);
}

.headerContainer{
    display: flex;
    justify-content: space-between;
}

.horizontalLine{
    background-color: #ea013a;
    width: 3px;
    height: 14px;
    margin-right: 10px;
}

.sectionOne{
    display: flex;
    max-width: 700px;
    justify-content: space-between;
}

section{
    margin-bottom: 61px;
}

.sectionOneDescriptions{
    margin-left: 14px;
    font-size: 14px;
    font-family: var(--intro-book);
}


.sectionHeaders{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: var(--intro-regular);
    margin-bottom: 10px;
}

.applyButton{
    background-color: #ea013a;
    border-radius: 8px;
    position: relative;
    font-family: var(--intro-regular);
    width: 240px;
    height: 48px;
    color: rgb(255, 255, 255);
    top: -25px;
}

.vacancyWrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

@media screen and (max-width: 1670px) {
    .vacancyWrapper{
        margin-left: 20px;
    }
}