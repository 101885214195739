.contactForm:not(:first-of-type):not(:last-of-type) {
    margin-left: 0px;
}

.contactForm {
    margin-top: 20px;
}

.contactTitle {
    font-size: 16px;
}

.contactForm textarea {
    width: 280px;
}

.contactPopupBackground {
    width: 100%;
    height: 100vh;
    background-color: rgb(20, 22, 29);
    z-index: 99990;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

.contactPopupContainer {
    width: 90%;
    border-radius: 10px;
    background-color: rgb(10, 12, 20);
    display: flex;
    flex-direction: column;
    animation: ApplyForm_fadeInPopup__S6Z05 .5s;
    animation-fill-mode: forwards;
    margin: auto;
}

@-webkit-keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.contactPopupHeader {
    margin-top: 20px;
}

.contactTitle {
    font-size: 18px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

.closeContactForm {
    width: 17px;
    height: 17px;
    cursor: pointer;
    user-select: none;
}

.inputTitle {
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(161, 162, 165);
    margin-left: 1px;
    margin-bottom: 5px;
}

.secondaryText {
    font-size: 16px;
    font-family: var(--intro-light);
    color: rgb(161, 162, 165);
}

.contactForms {
    display: flex;
    flex-direction: column;
}

.contactForm {
    display: flex;
    flex-direction: column;
    position: relative;
}

.contactForm input {
    border-radius: 6px;
    background-color: rgb(20, 22, 29);
    width: 100%;
    height: 48px;
    border: none;
    padding-left: 15px;
}

.contactForm textarea {
    border-radius: 6px;
    background-color: rgb(20, 22, 29);
    width: 100%;
    height: 76px;
    resize: none;
    border: none;
    padding-left: 15px;
    padding-top: 20px;
}

.textForm {
    margin-top: 30px;
    margin-left: 0px !important;
}

.contactForm input::placeholder, .contactForm textarea::placeholder {
    font-size: 14px;
    font-family: var(--intro-light);
    color: rgb(161, 162, 165)
}

.contactForm input, .contactForm textarea {
    font-size: 14px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
}

.sendUsMessage {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: rgb(234, 1, 58);
    border: none;
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-top: 30px;
    transition: background-color .2s;
}

.sendUsMessage:hover {
    background-color: rgb(205, 0, 50);
}

.emailInfo {
    position: absolute;
    background-image: var(--tooltip-sprite);
    background-repeat: no-repeat;
    width: 100%;
    height: 90px;
    top: -50px;
    font-size: 14px;
    font-family: var(--intro-light);
    color: rgb(255, 255, 255);
    display: none;
}

.emailInfo span {
    display: flex;
    margin-left: 10px;
    margin-top: 10px;

}

.questionMark {
    border-radius: 50%;
    background-color: rgb(25, 106, 255);
    width: 17px;
    height: 17px;
    font-size: 14px;
    font-family: var(--intro-light);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 45px;
}

.backToVacancy{
    display: block;
    margin-top: 30px;
}

.emailInfoWrapper:hover .emailInfo {
    display: flex;
}

.errorMessage {
    position: absolute;
    bottom: -25px;
    left: 10px;
    font-family: var(--intro-light);
    font-size: 14px;
    color: rgb(244, 13, 62);
    -webkit-animation: fadeInPopup 2s;
    animation: fadeInPopup 2s;
    animation-fill-mode: forwards;
    display: none;
}

.showErrorMessage {
    display: flex;
}