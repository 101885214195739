.header {
    display: inline-flex;
    background-color: rgb(20, 22, 29);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
    height: 60px;
    align-items: center;
    position: fixed;
    z-index: 9999;
    width: 100%;
    top: 0;
    justify-content: space-between;
}

.showHeader {
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

.headerMenuItems {
    display: flex;
    margin-left: 50px;
    justify-self: center;
    flex-direction: column;
    align-items: flex-end;
}

.headerMenuItem {
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none !important;
    font-family: var(--intro-regular);
    color: rgba(255, 255, 255, 0.6);
    position: relative;
    cursor: pointer;
    margin-top: 40px;
}

.burgerMenuIcon {
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -181px -49px;
    width: 32px;
    height: 23px;
}

.burgerIconWrapper {
    border-radius: 50%;
    background-color: rgb(40, 44, 57);
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 17px;
}
.closeIconWrapper{
    border-radius: 50%;
    background-color: rgb(40, 44, 57);
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.closeIcon{
    background-image: var(--mobile-smartsoft-sprite);
    background-position: -235px -44px;
    width: 27px;
    height: 26px;
}
.staticBackground {
    background-color: rgba(20, 22, 29, .8);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9990;
    left: 0;
    top: 0;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

.rightMenuContainer {
    width: 250px;
    height: 100vh;
    background-color: rgb(10, 12, 20);
    box-shadow: -6px 0px 10px 0px rgba(0, 0, 0, 0.4);
    position: fixed;
    right: 0;
    top: 0;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
    z-index: 99991;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



.activeMenuItem.headerMenuItem:after, .headerMenuItem:hover:after {
    background-size: 100% 100%;
}

.activeMenuItem {
    color: #ea013a;
}
.headerMenuItem.withSubMenu:hover .subMenuItemsWrapper {
    display: flex;
}

.withSubMenu {
    cursor: pointer;
    padding-right: 20px;
}

.headerMenuItem.withSubMenu::before {
    content: '';
    background-image: var(--smartsoft-sprite);
    background-position: -8px -136px;
    width: 13px;
    height: 8px;
    display: block;
    position: absolute;
    right: 0px;
    top: 6px;
}

.headerMenuItem.withSubMenu:hover::before {
    background-position: -44px -138px;
    top: 8px;
    right: -2px;
    transition: background-image .9s linear;
}
.headerMenuItem:hover {
    color: #ea013a;
    transition: color .9s;
}
.subMenuItemsWrapper:before {
    content: '';
    position: absolute;
    top: -50px;
    height: 50px;
    width: 100%;

}

.subMenuItemsWrapper {
    border-width: 1px;
    border-color: rgb(161, 162, 165);
    border-style: solid;
    background-color: rgb(20, 22, 29);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 55px;
    right: 0px;
    width: 165px;
    height: 225px;
    /* display: none; */
    flex-direction: column;
    -webkit-animation: fadeIn 3s;
    animation: fadeIn 3s;
    z-index: 10;
}



.subMenuItem {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none !important;
    height: 42px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    transition: color .9s;
}

.subMenuItem:not(:last-child) {
    border-bottom: 1px solid rgb(161, 162, 165);
}

.subMenuItem:hover {
    color: rgb(255, 255, 255);
    text-decoration: none !important;

}

.allItems:after {
    content: '';
    margin-left: 10px;
    background-image: var(--smartsoft-sprite);
    background-position: -6px -164px;
    width: 20px;
    height: 18px;
}

.activeSubMenuItem {
    color: rgb(255, 255, 255);
}

.socialMediaWrapper {
    display: flex;
    margin-left: auto;
    position: fixed;
    bottom:17px;
    right: 0;
}

.socialMediaItem {
    background-image: var(--smartsoft-sprite);
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-left: 13px;
    margin-right: 13px;
}

.socialMediaItem::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: -17px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size .9s, color .5s;
    pointer-events: none;
}

.socialMediaItem:hover:after {
    background-size: 100% 100%;
}

.instagram {
    background-position: -1px -244px;
}

.instagram:hover {
    background-position: -39px -244px;
}

.instagram:after {
    background-image: linear-gradient(#d10869, #d10869);
}

.instagram:hover:after {
    background-image: linear-gradient(#d10869, #d10869);
}

.twitter {
    background-position: -1px -197px;
}

.twitter:hover {
    background-position: -39px -197px;
}

.twitter:after {
    background-image: linear-gradient(#1da1f2, #1da1f2);

}

.twitter:hover:after {
    background-image: linear-gradient(#1da1f2, #1da1f2);
}

.linkedin {
    background-position: -3px -289px;
}

.linkedin:hover {
    background-position: -42px -289px;
}

.linkedin:after {
    background-image: linear-gradient(#2867b2, #2867b2);
}

.linkedin:hover:after {
    background-image: linear-gradient(#2867b2, #2867b2);
}

.youtube {
    background-position: 2px -328px;
}

.youtube:hover {
    background-position: -36px -328px;
}

.youtube:after {
    background-image: linear-gradient(#f30405, #f30405);
}

.youtube:hover:after {
    background-image: linear-gradient(#f30405, #f30405);
}

.facebook {
    background-position: 0px -363px;
}

.facebook:hover {
    background-position: -32px -364px;
}

.facebook:after {
    background-image: linear-gradient(#1778f2, #1778f2);
}

.facebook:hover:after {
    background-image: linear-gradient(#1778f2, #1778f2);
}