.termsBackground {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgb(20, 22, 29);
    z-index: 99990;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

.termsContainer {
    position: fixed;
    width: 800px;
    height: 600px;
    z-index: 99991;
    border-radius: 10px;
    background-color: rgb(10, 12, 20);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    -webkit-animation: fadeInPopup .5s;
    animation: fadeInPopup .5s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.iframeContainer {
    width: 100%;
    height: 450px;
    padding-left: 20px;
}


.termsPopupHeader {
    display: flex;
    margin: 20px;
    justify-content: space-between;
}

.termsTitle {
    font-size: 18px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}
.closeTerms {
    width: 17px;
    height: 17px;
    cursor: pointer;
    user-select: none;
}
.acceptTerms {
    width: 720px;
    height: 48px;
    border-radius: 8px;
    background-color: rgb(234, 1, 58);
    border: none;
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-top: 30px;
    transition: background-color .2s;
    margin: auto;
}

.acceptTerms:hover {
    background-color: rgb(205, 0, 50);
}

@media screen and (max-width: 1200px){
    .termsContainer{
        width: 320px;
        height: 600px;
    }
    .acceptTerms{
        width: 280px;
    }
}