.confirmContainer {
    border-radius: 10px;
    background-color: rgb(40, 44, 57);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    margin-left: 90px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: fixed;
    bottom: 50px;
    padding-right: 10px;
    z-index: 9999;
}

.confirmContainer span {    
    padding-left: 20px;
    font-family: var(--intro-book);
    font-size: 18px;
    color: rgb(255, 255, 255);
    padding-right: 40px;
}

.accept {
    border-radius: 6px;
    background-color: rgb(234, 1, 58);
    width: 120px;
    height: 44px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    
}

.accept:hover {
    background-color: rgb(205, 0, 50);
}

@media screen and (max-width: 1400px){
    .confirmContainer {
        margin-left: 40px;
    }
}
@media screen and (max-width: 1200px){
    .confirmContainer {
        width: 320px;
        height: 200px;
        display: flex;
        flex-direction: column;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: 0;
        padding-right: 0;
        bottom:0;
    }
    .confirmContainer span {
        font-size: 14px;
        padding-right: 20px;
    }
    .accept {
        width: 285px;
        margin-top: 20px;
    }
}