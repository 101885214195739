.newsItemWrapper {
    width: 810px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.newsLink {
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    margin-top: 30px;
}

.publishDate {
    font-size: 16px;
    display: flex;
    font-family: var(--intro-regular);
    color: rgba(255, 255, 255, 0.302);
    margin-top: 30px;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.newsTitle {
    font-size: 30px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-top: 5px;
}

.newsImage {
    width: 470px;
    height: 470px;
    border-radius: 10px;
    user-select: none;
    align-self: center;
    margin-top: 20px;
}

.newsDescription {
    font-size: 16px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    max-width: 810px;
    justify-content: center;
    margin-top: 25px;
}

.backToNews {
    font-size: 16px;
    font-family: var(--intro-regular);
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    text-decoration: none !important;
}

.backToNews:hover {
    color: rgb(48, 145, 255);
}
.headerContainer{
    display: flex;
    justify-content: space-between;
}
.shareImage {
    width: 22px;
    height: 22px;
    cursor: pointer;
}