.gamesContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1230px;
    position: relative;
    border-radius: 10px;
    margin: auto;
}

.gamesWrapper{
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 210px 210px 210px 210px 210px;
    margin-top: 15px;
}
.gameItem {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gameItem img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    -webkit-transition: all 1.5s ease;
    -moz-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    -ms-transition: all 1.5s ease;
    transition: all 1.5s ease;
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
}

.gameItem:hover img {
    filter: blur(2px);
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -moz-transform: scale(1.5); /* Firefox */
    -ms-transform: scale(1.5); /* IE 9 */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.2);
}

.tryDemo {
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: rgb(234, 1, 58);
    width: 45px;
    height: 45px;
    font-size: 16px;
    font-family: var(--intro-regular);
    position: absolute;
    color: rgb(255, 255, 255) !important;
    text-decoration: none !important;
    opacity: 0;
    text-transform: uppercase;
}



.gameItem:hover .tryDemo {
    display: flex;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    animation-fill-mode: forwards;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.tryDemo:after{
    content:'';
    background-image: var(--smartsoft-sprite);
    background-position: -457px 2px;
    width: 20px;
    height: 23px;
}
.gameItem{
    width: 210px;
    height: 160px;
    margin: 15px 0;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.gameItem:first-of-type{
    width: 450px;
    height: 350px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;    
}

.gameItem .tryDemoTitle{
    display: none!important;
}
.gameItem:first-of-type .tryDemoTitle{
    display: flex!important;
}

.gameItem:first-of-type .tryDemo{
    width: 163px;
    height: 50px;
}

.gameTitle{
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 40px;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-family: var(--intro-regular);
    opacity: 0;
}
.gameItem:hover .gameTitle{
    display: flex;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    animation-fill-mode: forwards;
}
.gameItem:first-of-type .gameTitle{
    height: 60px;
}


/*Keno*/

.Keno .gamesContainer{
    max-width: 770px;
}
.Keno .gamesWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Keno .gameItem:first-of-type .gameTitle{
    height: 40px;
}
.Keno .gameItem:first-of-type .tryDemo{
    width: 45px;
    height: 45px;
}
.Keno .gameItem:first-of-type .tryDemoTitle{
    display: none!important;
}
.Keno .gameItem:first-of-type{
    width: 210px;
    height: 160px;
}


/*JetX*/
/*Keno*/

.JetX .gamesContainer{
    max-width: 770px;
}
.JetX .gamesWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.JetX .gameItem:first-of-type .gameTitle{
    height: 40px;
}
.JetX .gameItem:first-of-type .tryDemo{
    width: 45px;
    height: 45px;
}
.JetX .gameItem:first-of-type .tryDemoTitle{
    display: none!important;
}
.JetX .gameItem:first-of-type{
    width: 210px;
    height: 160px;
}






@media screen and (min-width: 1024px) and (max-width: 1298px){
    .gamesWrapper {
        display: grid;
        grid-template-columns: 210px 210px 210px 210px;
    }
}