.newsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.menuWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    position: relative;
}

.chooserSuggestions {
    border-width: 1px;
    border-color: rgb(161, 162, 165);
    border-style: solid;
    background-color: rgb(40, 44, 57);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
    margin-top: 5px;
    position: absolute;
    z-index: 10;
    top: 40px;
}

.menuWrapper a {
    border-width: 1px;
    border-color: rgb(161, 162, 165);
    border-style: solid;
    justify-content: center;
    width: 320px;
    height: 44px;
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    flex-direction: column;
    font-family: var(--intro-regular);
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    text-decoration: none;
    transition: background-color .9s;
    position: relative;
}

.menuWrapper a:hover {
    background-color: rgb(40, 44, 57);
}

.chooserSuggestions .All {
    padding-left: 60px;
}

.chooserSuggestions .All::before, .selectedItem.All::before {
    content: '';
    display: block;
    margin-left: 10px;
    background-image: var(--all-sprite);
    background-position: 0px 0px;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 15px;
    top: 11px;
}

.selectedItem.All::before {
    top: 7px;

}

.activeNews {
    background-color: rgb(40, 44, 57);
}

.selectedItem.All {
    padding-left: 60px;
}

.selectedItem {
    background-color: rgb(40, 44, 57);
    width: 322px;
    height: 36px;
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: var(--intro-regular);
    padding-left: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
}

.selectedItem:after {
    content: '';
    background-image: var(--smartsoft-sprite);
    background-position: -8px -136px;
    width: 13px;
    height: 8px;
    display: block;
    position: absolute;
    right: 15px;
    top: 13px;
}

.newsItemWrapper {
    display: flex;
    width: 320px;
    height: 490px;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.newsImage {
    width: 320px;
    height: 320px;
    border-radius: 10px;
    user-select: none;
}

.newsHeadWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.newsHeadWrapperInfo {
    background-color: rgba(0, 0, 0, .8);
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.newsTitle {
    font-size: 16px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: var(--intro-regular);
    margin-left: 20px;
    max-width: 67%;
}

.publishDate {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: var(--intro-regular);
    margin-right: 20px;
    justify-content: center;
    align-items: flex-end;
}

.monthName {
    font-size: 16px;
    font-family: var(--intro-book);
}

.newsDescription {
    font-size: 14px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    max-width: 320px;
    margin-top: 10px;
    display: inline-block;
    position: relative;
    overflow-wrap: break-word;
}

.seeAll {
    color: rgb(48, 145, 255);
    text-transform: uppercase;
    font-family: var(--intro-regular);
    font-size: 16px;
    position: relative;
    cursor: pointer;
    text-decoration: none !important;
}

.seeAll:hover {
    color: rgb(48, 145, 255);
}

/*
.seeAll:before{
    content:'';
    display: block;
    background-image: -moz-linear-gradient(180deg, rgba(10, 12, 20, 0.8) 0%, rgba(10, 12, 20, 0) 100%);
    background-image: -webkit-linear-gradient(180deg, rgba(10, 12, 20, 0.8) 0%, rgba(10, 12, 20, 0) 100%);
    background-image: linear-gradient(180deg, rgba(10, 12, 20, 0.8) 0%, rgba(10, 12, 20, 0) 100%);
    left: 0px;
    top: 0px;
    width: 120px;
    height: 16px;
    position: absolute;
}*/
