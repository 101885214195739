.footerContainer {
    display: flex;
    /*    margin-left: 80px;*/
    flex-direction: column;
    /* margin-bottom: 60px; */
}

.footerBottomLine {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 5px;
    flex-direction: column;
}

.termsAndConditions {
    font-family: var(--intro-regular);
    font-size: 14px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: color .9s;
    margin-top: 20px;
}

.footerText{
    width: 90%;
    text-align: center;
    font-family: var(--intro-book);
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    margin-top: 20px;

}

.termsAndConditions:hover {
    color: rgb(255, 255, 255);
}

.allRights {
    color: rgb(116, 117, 121);
    font-family: var(--intro-book);
    font-size: 14px;
    margin-left: 20px;
    align-self: flex-start;
}

.certificatesAndContactsWrapper {
    height: 60px;
    background-color: rgb(20, 22, 29);
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    position: relative;
    margin-right: 70px;
}

.certificates {
    height: 60px;
    background-color: #14161d;
    border-radius: 6px;
    margin-top: 20px;
    position: relative;
    margin-left: 70px;
    margin-right: 70px;
}

.certificateItem{
    transition: opacity .9s;
    opacity: .5;
    margin-left: 40px;
    margin-right: 40px;
}

.certificateItem:hover{
    opacity: 1;
}

.circle{
    width: auto !important;
    padding: 5px;
    border: rgb(133,134,138) 1px solid;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(133,134,138);
    font-family: var(--intro-bold);
}

.one {
    background-image: var(--smartsoft-sprite);
    background-position: -122px -52px;
    width: 52px !important;
    height: 49px !important;
}

/*.certificates a:nth-of-type(2) {
    background-image: var(--smartsoft-sprite);
    background-position: -108px -113px;
    width: 76px;
    height: 34px;
}*/
.two{
    background-image: url('/assets/images/MGA.png');
    width: 130px !important;
    height: 55px !important;
    background-size: 125px auto;
    background-repeat: no-repeat;
    background-position-y: 5px;
}

.three{
    background-image: url('/assets/images/ONJN4.png');
    width: 55px !important;
    height: 55px !important;
    background-size: 51px auto;
    background-repeat: no-repeat;
    background-position-y: 3px;
}

.four{
    background-image: url('/assets/images/RGF.png');   
    width: 180px !important;
    height: 55px !important;
    background-size: 180px auto;
    background-repeat: no-repeat;
    background-position-y: -35px;
}

.five{
    background-image: url('/assets/images/GamblingAssociates.png');   
    width: 235px !important;
    height: 47px !important;
    background-repeat: no-repeat;
}

.six{
    background-image: url('/assets/images/ecogra.png');     
    width: 160px !important;
    height: 47px !important;
    background-size: 160px auto;
    background-repeat: no-repeat;
}

.contactContainer {
    display: flex;
    flex-direction: column;
}

.contactContainer a {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: var(--intro-regular);
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.phoneNumber::before {
    display: block;
    content: '';
    background-image: var(--smartsoft-sprite);
    background-position: 0px -407px;
    width: 22px;
    height: 30px;
    margin-right: 10px;

}

.phoneNumber:hover::before {
    background-position: -37px -407px;
}

.map::before {
    display: block;
    content: '';
    background-image: var(--smartsoft-sprite);
    background-position: -196px 0px;
    width: 20px;
    height: 23px;
    margin-right: 10px;
}

.map:hover::before {
    background-position: -249px 0px;
}

.mail::before {
    display: block;
    content: '';
    background-image: var(--smartsoft-sprite);
    margin-right: 10px;
    background-position: -38px -449px;
    width: 26px;
    height: 25px;
}

.mail:hover::before {
    background-position: 3px -449px;
}

.subscribeUsContainer {
    position: relative;
    height: 300px;
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
}

.subscribeWrapper {
    background-color: rgb(20, 22, 29);
    height: 270px;
    width: 100%;
}

.subscribeUs {
    position: relative;
    margin-top: 30px;
}

.mummyImage {
    position: absolute;
    bottom: 65px;
    right: 0px;
    user-select: none;
}

.subscribeTitle {
    font-size: 20px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-left: 20px;
}

.subscribeTitle span {
    background-color: rgb(40, 44, 57);
    display: flex;
    justify-content: center;
    width: 160px;
    height: 35px;
    align-items: center;
}

.subscribeDescription {
    font-size: 14px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    max-width: 312px;
    margin-top: 30px;
    margin-left: 20px;
}

.subscribeInputContainer {
    width: 320px;
    height: 50px;
    background-color: rgb(10, 12, 20);
    border-radius: 6px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin-left: 20px;
}

.subscribeInput {
    background: transparent;
    color: rgba(172, 185, 226, .502);
    margin-left: 20px;
    width: 180px;
    height: 48px;
    border: none;
}

.subscribeInput::placeholder {
    font-family: var(--intro-light);
    color: rgba(172, 185, 226, 0.502);
}

.subscribeButton {
    width: 100px;
    height: 34px;
    border-radius: 4px;
    background-color: rgb(234, 1, 58);
    cursor: pointer;
    color: #fff !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-right: 10px;
    border: none;
    font-family: var(--intro-regular);
    text-decoration: none !important;
    transition: background-color .9s;
}

.subscribeButton:hover {
    background-color: rgb(205, 0, 50);
}

/*

@media screen and (max-width: 1700px) {
    .mummyImage {
        right: 100px;
    }

    .subscribeUs {
        left: 200px;
    }
}

@media screen and (max-width: 1500px) {
    .mummyImage {
        right: 100px;
    }

    .subscribeUs {
        left: 200px;
    }
}

@media screen and (max-width: 1400px) {
    .certificates div, .certificates a {
        margin-left: 10px;
        margin-right: 10px;
    }

    .contactContainer a {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mummyImage {
        right: 100px;
    }

    .subscribeUs {
        left: 100px;
    }
}
*/


@media screen and (max-width: 1200px) {
    .certificateItem{
        margin-left: 20px;
        margin-right: 20px;
    }

    .certificatesAndContactsWrapper {
        justify-content: center;
    }
}