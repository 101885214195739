.appliedContainer {
    position: relative;
    width: 100%;
    height: 50vh;
}

.CheckIcon{
    background: url('/assets/images/CheckIcon.png') no-repeat;
    width: 30px;
    height: 40px;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    z-index: 1;
    position: absolute;
    margin: -100.75px 0 0 -150px;
}

.header {
    height: 1px;
    font-family: var(--intro-regular);
    font-size: 18px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

}

.description {
    font-family: var(--intro-book);
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-top: 30px;
    max-width: 300px;
    text-align: justify;
}

.description span {
    display: block;
    margin-top: 50px;
}