.menuLeft {
    /* height: 100vh; */
    /* background-color: rgb(20, 22, 29); */
    position: fixed;
    width: 165px;
    /* margin-top: 5px; */
    /* transition: width .9s ease; */
    /* z-index: 1000; */
}
/* 
.menuLeft:hover {
    width: 185px
} */

/* .menuLeft:hover .leftMenuItem::after {
    right: 0px;
} */

.leftMenuItemIcon {
    background-image: var(--smartsoft-sprite);
    width: 70px;
    height: 70px;
    margin-left: 3px;
}

.leftMenuItemIcon.JetX {
    background-position: -85px -266px;

}

.leftMenuItem:hover .JetX, .activeLeftMenuItem .JetX{
    background-position: -174px -307px;
    color: rgb(22, 156, 241);

}

.leftMenuItemIcon.XGames {
    background-position: -83px -331px;

}

.leftMenuItem:hover .XGames, .activeLeftMenuItem .XGames {
    background-position: -172px -406px;
    color: rgb(241, 91, 67);
}

.leftMenuItemIcon.Slots {
    background-position: -84px -408px;

}

.leftMenuItem:hover .Slots, .activeLeftMenuItem .Slots {
    background-position: -216px -213px;
    color: rgb(253, 187, 56);
}

.leftMenuItemIcon.Casino {
    background-position: -181px -28px;

}

.leftMenuItem:hover .Casino, .activeLeftMenuItem .Casino {
    background-position: -282px -23px;
    color: rgb(30, 202, 116);
}


.leftMenuItemIcon.Keno {
    background-position: -182px -109px;

} 

.leftMenuItem:hover .leftMenuItemIcon.Keno, .activeLeftMenuItem .Keno {
    background-position: -278px -119px;
    color: rgb(165, 86, 233);
}

.leftMenuItem {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    position: relative;
    height: 45px;
    /* margin-top: 35px; */
    justify-content: flex-end;
}

.categoryTitle {
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255);
    font-family: var(--intro-regular);
    text-transform: uppercase;
}

/* .menuLeft:hover .categoryTitle {
    display: flex;
} */

.leftMenuWrapper {
    display: flex;
    z-index: 1;
    flex-direction: column;
    /* top: 65px; */
    position: relative;
}

.leftMenuItem::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    right: -4px;
    bottom: 2px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 0%;
    transition: background-size .9s, color .5s, right .9s;
    pointer-events: none;
    background-image: linear-gradient(#ea013a, #ea013a);
 
}

/* .leftMenuItem:hover::after {
    background-size: 100% 100%;
} */

/* .activeLeftMenuItem::after {
    background-size: 100% 100%;
}

.leftMenuItem.Slots::after {
    background-image: linear-gradient(rgb(253, 187, 56), rgb(253, 187, 56));
}

.leftMenuItem.Casino::after {
    background-image: linear-gradient(rgb(30, 202, 116), rgb(30, 202, 116));
}

.leftMenuItem.Keno::after {
    background-image: linear-gradient(rgb(165, 86, 233), rgb(165, 86, 233));
} */