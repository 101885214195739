:root{
    --body-background:#0a0c14;
    --body-home-background:#060314;
    --smartsoft-sprite:url('/assets/images/sprites/SmartSoftSprite1.png');
    --smartsoft-logo:url('/assets/images/Logo.png');
    --mobile-smartsoft-sprite:url('/assets/images/sprites/MobileSprites.png');
    --all-sprite:url('/assets/images/all.png');
    --tooltip-sprite:url('/assets/images/Tooltip.png');
    --login-casino-sprite:url('/assets/images/LoginCasinoAward.png');
    --intro-regular:'Intro-Regular';
    --intro-book:'Intro-Book';
    --intro-light:'Intro-Light';
    --intro-bold:'Intro-Bold';
    --intro-black:'Intro-Black';
}


@font-face {
    font-family: 'Intro-Book';
    src: local('Intro-Book'), url('/assets/fonts/Intro/Intro-Book.otf');
}
@font-face {
    font-family: 'Intro-Regular';
    src: local('Intro-Regular'), url('/assets/fonts/Intro/Intro-Regular.otf');
}
@font-face {
    font-family: 'Intro-Light';
    src: local('Intro-Light'), url('/assets/fonts/Intro/Intro-Light.otf');
}
@font-face {
    font-family: 'Intro-Bold';
    src: local('Intro-Bold'), url('/assets/fonts/Intro/Intro-Bold.otf');
}
@font-face {
    font-family: 'Intro-Black';
    src: local('Intro-Black'), url('/assets/fonts/Intro/Intro-Black.otf');
}
* {
    outline: none !important;
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden!important;
    background-color: var(--body-background);
}
.mainContainer{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* margin-left: 80px; */
}

.grecaptcha-badge { visibility: hidden; }


::-webkit-scrollbar { width: 3px; }

::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

::-webkit-scrollbar-thumb {
    background-color:rgb(40, 44, 57);

}


@media screen and (max-width: 1200px){
    .mainContainer{
        margin-left:0px;
    }

}