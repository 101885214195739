.partnersContainer {
    /* margin-left: 80px; */
    margin-top: 65px;
    height: 130px;
}

.partnersTitle {
    background-color: rgb(22 27 44);
    width: 260px;
    height: 44px;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: var(--intro-regular);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.partnersSlider {
    height: 60px;
    background-color: #14161d;
    border-radius: 6px;
    margin-top: 20px;
    position: relative;
    margin-left: 70px;
    margin-right: 70px;
}

.slick-slide {
    vertical-align: middle;
}

.slick-track{
    display: flex !important;
    align-items: center;
}

.slider-a{
    display: block !important;
}

.slider-item {
    width: unset !important;
    margin-left: 22.5px;
    margin-right: 22.5px;
    max-height: 60px;
    filter: contrast(0);
}

.slick-arrow {
    border-radius: 10px;
    background-color: rgb(20, 22, 29) !important;
    width: 50px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .9s;
}

.slick-prev {
    left: -60px !important;
    width: 50px !important;
    height: 60px !important;
}

.slick-prev::before {
    display: block;
    content: '' !important;
    background-image: var(--smartsoft-sprite);
    background-position: -378px 0px;
    width: 25px;
    height: 25px;
    position: relative;
    left: 10px;
}

.slick-arrow:hover {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1) !important;
}

.slick-next::before {
    display: block;
    content: '' !important;
    background-image: var(--smartsoft-sprite);
    background-position: -405px 1px;
    width: 37px;
    height: 25px;
    position: relative;

}

.slick-next {
    right: -60px !important;
    width: 50px !important;
    height: 60px !important;
}

.slick-slider {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (max-width: 1200px) {
    .partnersContainer {
        margin-left: 0px;
    }

    .partnersSlider {
        margin-left: 0;
        margin-right: 0;
    }

    .partnersTitle {
        margin-top: 20px;
        width: 154px;
        height: 30px;
        font-size: 16px;
    }
}