.licensesContainer {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.licensesWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.licensesHeader {
    background-color: rgb(40, 44, 57);
    font-family: var(--intro-regular);
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    padding-left:10px;
    padding-right:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
.licensesDescription{
    width: 90%;
    display: flex;
    margin-top: 30px;
    font-family: var(--intro-book);
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-left: 5px;
}
.licensesItems{
/*    width: 1200px;*/
    display: flex;
    flex-direction: column;
}
.licenseItem{
    margin-top: 10px;
    background-color: #22242a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
}
.licenseItemLeft{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.licenseCountry {
    font-family: var(--intro-regular);
    font-size: 16px;
    color: rgba(255, 255, 255);
    text-transform: uppercase;
}
.licenseItemInfo{
    font-family: var(--intro-book);
    font-size:16px;
    color: rgba(255, 255, 255, 0.302);
    /*    margin-top: 5px;*/
}
.licenseIcon{
    max-height: 70px;
    margin-left: 20px;
    margin-top: 10px;
}


@media screen and (max-width: 1330px){
    .licensesDescription{
        width: 1000px;
    }
    /*.licensesItems{
        width: 1000px;
    }*/
}