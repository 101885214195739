.popupBackground {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgb(20, 22, 29);
    z-index: 99990;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

.popupContainer {
    position: fixed;
    z-index: 99991;
    border-radius: 10px;
    background-color: rgb(10, 12, 20);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    -webkit-animation: fadeInPopup .5s;
    animation: fadeInPopup .5s;
    animation-fill-mode: forwards;
    width: 642px;
    height: 230px;
}

@-webkit-keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: var(--intro-regular);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin-top: 20px;
}

.container {
    font-size: 16px;
    font-family: var(--intro-book);
    color: rgb(255, 255, 255);
    margin: 20px 20px 0px 20px;
}

.footer {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: var(--intro-regular);
    text-transform: uppercase;
    margin-top: 20px;
}

.yes {
    border-radius: 6px;
    background-color: rgb(234, 1, 58);
    width: 120px;
    height: 44px;
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
}
.no{
    margin-left: 10px;
    background-color: rgba(161, 162, 165, .4);
    width: 120px;
    height: 44px;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 6px;
}

@media  screen and (max-width: 1200px) {
    .popupContainer{
        width: 320px;
        height: 260px;
    }
    .header{
        font-size: 16px;
        justify-content: flex-start;
        margin-left: 20px;
    }
    .container{
        font-size: 14px;
    }
}