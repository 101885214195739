.aboutUsContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.aboutUsImage {
    position: relative;
    margin-top: 580px;
    width: 360px;
    right: 0;
}

.whoWeAreContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 20px;
    z-index: 1;
    position: absolute;
}

.whoWeAreHeader {
    /* height: 30px; */
    max-width: 300px;
    background-color: rgb(22 27 44);
    font-family: var(--intro-regular);
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

}

.whoWeAreDescription {
    font-family: var(--intro-book);
    font-size: 14px;
    color: rgb(255, 255, 255);
    margin-top: 30px;
    max-width: 300px;

}

.whoWeAreDescription span {
    display: block;
    margin-top: 20px;
}

.features {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 10px;
}

.featureItem {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.featureTitle {
    color: rgb(234, 1, 58);
    font-family: var(--intro-bold);
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 10px;
}

.featureDescription {
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-family: var(--intro-book);
    margin-left: 10px;
}

.statistic {
    display: flex;
    margin-top: 90px;
    flex-direction: column;
    align-items: center;
}

.statisticItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

/*.statisticItem:not(:first-of-type) {
    margin-left: 70px;
}*/

.statisticHeader {
    height: 30px;
    background-color: rgb(40, 44, 57);
    font-family: var(--intro-regular);
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.533;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

}

.statisticNumber {
    font-size: 40px;
    color: rgb(234, 1, 58);
    font-family: var(--intro-bold);
}

.mileStonesContainer {
    display: flex;
    justify-content: center;
    height: 825px;
    align-items: center;
    margin-top: 100px;
}

.mileStonesContainer1 {
    display: flex;
    justify-content: center;
    height: 825px;
    align-items: center;
    margin-top: 100px;
}

.mileStonesWrapper1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 390px 0 150px 0;
    position: relative;
}

.mileStone {
    position: absolute;
}

.historyItem {
    width: 240px;
    height: 287px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: relative;
    border-radius: 6px;
    background-color: rgb(20, 22, 29);
}

.historyHead {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 20px 10px;
    text-align: center;
    color: rgb(234, 1, 58);
    font-family: var(--intro-bold);
    font-size: 16px;
}

.historyDescription {
    font-size: 14px;
    color: #fff;
    padding: 0 20px 20px 20px;
    font-family: var(--intro-book);
}

.historyItem:nth-child(2n+1) {
    margin-top: -441px;
}

.historyItem:nth-child(1) {
    left: 30px;
}

.historyItem:nth-child(2n+2) {
    margin-top: -110px;
}

.historyItem:nth-child(2) {
    left: 12px;
    top: 201px;
}

.historyItem:after {
    color: rgb(255, 255, 255);
    width: 122px;
    height: 122px;
    border-radius: 50%;
    font-size: 30px;
    font-family: var(--intro-regular);
    display: flex;
    bottom: -183px;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 59px;
    transition: background-color .9s;
}

.historyItem:nth-child(2n+2):after {
    bottom: 348px;
    left: 57px;
}

.historyItem:nth-child(3) {
    left: -10px;
}

.historyItem:nth-child(4) {
    left: -28px;
    top: 201px;
}

.historyItem:hover:after {
    background-color: rgb(20, 22, 29);
}

.historyItem:nth-child(1):after {
    content: '2015';
}

.historyItem:nth-child(2):after {
    content: '2016';
}

.historyItem:nth-child(3):after {
    content: '2017';
}

.historyItem:nth-child(4):after {
    content: '2018';
}

.mileStonesContainer1 .historyItem:nth-child(1):after {
    content: '2019';
}

.mileStonesContainer1 .historyItem:nth-child(2):after {
    content: '2020';
}

.mileStonesContainer1 .historyItem:nth-child(3):after {
    content: '2021';
}

.mileStonesContainer1 .historyItem:nth-child(4):after {
    content: '2022';
}

/*

@media screen and (orientation: landscape) {
    .aboutUsContainer {
        display: flex;
        justify-content: center;
    }

    .whoWeAreContainer {
        left: unset;
    }
}*/
.milestoneItemsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
.mileStoneItem{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
}
.mileStoneRight{
    width: 240px;
    height: 300px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: relative;
    border-radius: 6px;
    background-color: rgb(20, 22, 29);
}
.mileStoneHead {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 20px 10px;
    text-align: center;
    color: rgb(234, 1, 58);
    font-family: var(--intro-bold);
    font-size: 16px;
}
.mileStoneDescription {
    font-size: 14px;
    color: #fff;
    padding: 0 20px 20px 20px;
    font-family: var(--intro-book);
}

.mileStoneItem .mileStoneRight:before {
    color: rgb(255, 255, 255);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 18px;
    font-family: var(--intro-regular);
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: -70px;
    top: 126px;
    transition: background-color .9s;
}

.mileStoneItem:nth-child(1) .mileStoneRight:before{
    content: '2015';
}
.mileStoneItem:nth-child(2) .mileStoneRight:before{
    content: '2016';
}
.mileStoneItem:nth-child(3) .mileStoneRight:before{
    content: '2017';
}
.mileStoneItem:nth-child(4) .mileStoneRight:before{
    content: '2018';
}
.mileStoneItem:nth-child(5) .mileStoneRight:before{
    content: '2019';
}
.mileStoneItem:nth-child(6) .mileStoneRight:before{
    content: '2020';
}
.mileStoneItem:nth-child(7) .mileStoneRight:before{
    content: '2021';
}
.mileStoneItem:nth-child(8) .mileStoneRight:before{
    content: '2022';
}